import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
// import { db } from "../firebase-config.js";
// import { collection, getDocs } from "firebase/firestore";

export default function Home() {
  const data = [
    {
      title: "Github",
      link: "https://github.com/yassine20011",
      icon: "https://img.icons8.com/ios-filled/50/171515/github.png",
    },
    {
      title: "Youtube",
      link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ&feature=youtu.be",
      icon: "https://img.icons8.com/ios-filled/50/000000/youtube-play.png",
    },
    {
      title: "Twitter",
      link: "https://twitter.com/yassineamjad3?lang=en",
      icon: "https://img.icons8.com/ios-filled/50/00acee/twitter.png",
    },
    {
      title: "Portfolio",
      link: "https://yassineamjad.me",
      icon: "https://yassineamjad.me/favicon.ico",
    },
    {
      title: "LinkedIn",
      link: "https://ma.linkedin.com/in/yassine-amjad/en?trk=people-guest_people_search-card",
      icon: "https://img.icons8.com/ios-filled/50/0072b1/linkedin.png",
    },
  ];

  return (
    <React.Fragment>
      <header className="App-header">
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="https://avatars.githubusercontent.com/u/59234686?s=400&u=61092e98ce7621edca2168021b0aa4dff1487586&v=4"
              sx={{ width: "150px", height: "150px" }}
            />
            <Typography
              variant="h1"
              component="h1"
              sx={{
                color: "Black",
                fontSize: "1.5em",
                textAlign: "center",
                margin: "1em",
                fontFamily: "Ubuntu",
                letterSpacing: "2px",
                fontWeight: "bold",
              }}
            >
              Yassine Amjad
            </Typography>
            {data.map((button, index) => (
              <Button
                key={index}
                variant="text"
                id="button"
                href={button.link}
                target="_blank"
                sx={{
                  width: "100%",
                  marginTop: "1em",
                  backgroundColor: "white",
                  borderBottom: "1px solid #333",
                  textTransform: "none",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  letterSpacing: "2px",
                  textAlign: "left",
                  paddingLeft: "1.5em",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <img
                  style={{
                    marginRight: "1em",
                    width: "20px",
                    height: "20px",
                    position: "relative",
                    top: "3px",
                  }}
                  alt="icon"
                  src={button.icon}
                />
                {button.title}
              </Button>
            ))}
          </Grid>
        </Grid>
      </header>
      <footer className="App-footer">
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "Black",
            fontSize: "1em",
            textAlign: "center",
            margin: "1em",
            fontWeight: "bold",
            fontFamily: "Ubuntu",
          }}
        >
          Made with ❤️ by Yassine Amjad
        </Typography>
      </footer>
    </React.Fragment>
  );
}
