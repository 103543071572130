import "./App.css";
import Home from "./components/home.jsx";
// import Add from "./components/add.jsx";
import { Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}/>
        {/* <Route path="/add" element={<Add />}/> */}
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </div>
  );
}
export default App;
